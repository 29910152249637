import React from 'react'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from "gatsby"
import Heading from '../../../components/ui/heading'
import Anchor from '../../../components/ui/anchor'
import {Container, Row, Col} from '../../../components/ui/wrapper'
import SectionTitle from '../../../components/ui/section-title'
import FeatureBox from '../../../components/box-image/layout-one'
import {SectionWrap} from './features-area.style'

const FeaturesArea = (props) => {
    const featuredDataQuery = useStaticQuery(graphql `
        query infotechnoFeatureQueryData {
            infotechnoJson(id: {eq: "infotechno-featured-content"}) {
                title
                subtitle
            }
            allItServicesJson(sort: {order: DESC, fields: id}, filter: {is_featured: {eq: true}}, limit: 3) {
                edges {
                  node {
                    fields{
                        slug
                    }
                    id
                    title
                    excerpt
                    icon {
                      img{
                        childImageSharp{
                            fixed(width:100, height: 108, quality: 100 ){
                                ...GatsbyImageSharpFixed_tracedSVG
                            }
                        }
                      }
                    }
                  }
                }
            }
        }
    `);



    const featureSecData = featuredDataQuery.infotechnoJson;
    const featureData = featuredDataQuery.allItServicesJson.edges;
    const {featureBoxStyles, linkStyle, headingStyle,industries,products} = props;

    const dummyIcon = featureData[0].node.icon.img.childImageSharp

    //console.log(4)
    //console.log(products)

    return(
        <SectionWrap>
            <Container>
                <Row>
                    <Col lg={12}>
                        <SectionTitle
                            subtitle={"Gartantier stilt av forsikringsselskaper"}
                            title={"Ulike typer garantiforsikringer <span>erstatter tradisjonelle bankgarantier</span> uten å binde kapital"}
                        />
                    </Col>
                </Row>
                <Row>
                    {products.edges.map((product,i) => {
                        //console.log(product)
                        if(product.node.bygg && product.node.bygg === true){
                            return(
                                <Col lg={6} md={6} key={"prod" + i}>
                                    <FeatureBox
                                        {...featureBoxStyles}
                                        title={product.node.substitute_products[0]}
                                        imageSrc={product.node.image?product.node.image.asset:dummyIcon}
                                        desc={product.node.explanation}
                                        path={'/' + (product.node.slug ? product.node.slug.current : '')}
                                    />
                                </Col>
                            )
                        }
                    })}
                </Row>
                <Row>
                    <Col lg={12}>
                        <Heading {...headingStyle}>Behov for en garanti? Ta kontakt for et <Anchor {...linkStyle} path="/tilbud-garantiforsikring">uforpliktende tilbud!</Anchor></Heading>
                    </Col>
                </Row>
            </Container>
        </SectionWrap>
    )
}

FeaturesArea.propTypes = {
    featureBoxStyles: PropTypes.object,
    headingStyle: PropTypes.object,
    linkStyle: PropTypes.object
}

FeaturesArea.defaultProps = {
    featureBoxStyles: {
        mt: '60px',
        responsive: {
            small: {
                mt: '47px'
            }
        }
    },
    headingStyle: {
        as: 'h3',
        fontSize: '18px',
        fontweight: 500,
        lineHeight: 1.40,
        color: '#333333',
        mt: '60px',
        textalign: 'center',
        responsive: {
            small: {
                mt: '45px'
            }
        }
    },
    linkStyle: {
        layout: 'underline',
        hover: {
            layout: 2
        }
    }
}

export default FeaturesArea;
